import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _afb4bf02 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _30978903 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _2d538d02 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _83113b08 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _0d912582 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _71cd4396 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _14ec37ad = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _6f7b3a94 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _0491ca99 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _07c5b4f4 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _8f700d84 = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _084d48cb = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3ab92ad4 = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _2cc3366e = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _13c158c2 = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _7f31b8b2 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _25a8c122 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _069eae51 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _40963a74 = () => interopDefault(import('../pages/refund/_id.vue' /* webpackChunkName: "pages/refund/_id" */))
const _0feea238 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _c8845a48 = () => interopDefault(import('../pages/unsubscribe/_hash.vue' /* webpackChunkName: "pages/unsubscribe/_hash" */))
const _48a683fc = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _b3220192 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _afb4bf02,
    name: "index___en"
  }, {
    path: "/en/cart",
    component: _30978903,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _2d538d02,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _83113b08,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _0d912582,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _71cd4396,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _14ec37ad,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _6f7b3a94,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _0491ca99,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _07c5b4f4,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _8f700d84,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _084d48cb,
    name: "search___en"
  }, {
    path: "/en/tickets",
    component: _3ab92ad4,
    name: "tickets___en"
  }, {
    path: "/en/profile/tickets",
    component: _2cc3366e,
    name: "profile-tickets___en"
  }, {
    path: "/en/categories/:cid",
    component: _13c158c2,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _7f31b8b2,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _25a8c122,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _069eae51,
    name: "payment-hash___en"
  }, {
    path: "/en/refund/:id?",
    component: _40963a74,
    name: "refund-id___en"
  }, {
    path: "/en/restore/:key",
    component: _0feea238,
    name: "restore-key___en"
  }, {
    path: "/en/unsubscribe/:hash?",
    component: _c8845a48,
    name: "unsubscribe-hash___en"
  }, {
    path: "/en/events/:eid/tickets",
    component: _48a683fc,
    name: "events-eid-tickets___en"
  }, {
    path: "/en/:slug",
    component: _b3220192,
    name: "slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
